<template>
  <div>
    <button @click="handleLogin()">Log In</button>
  </div>
</template>

<script setup>
import { useAuth0 } from '@auth0/auth0-vue';

const { loginWithRedirect } = useAuth0();

function handleLogin() {
  loginWithRedirect({
    appState: {
      target: '/chat'
    }
  });
}
</script>

<style lang="scss" scoped></style>
