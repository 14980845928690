<template>
  <div class="flex m-auto md:w-3/5 items-center gap-2 w-full px-4 md:px-0">
    <form
      @submit.prevent="submitMessage"
      class="border border-neutral-600 rounded flex w-full h-full"
    >
      <textarea
        cols="30"
        rows="2"
        @keypress.enter="handleEnter"
        v-model="userMessage"
        class="w-full resize-none p-2"
        id="textareaInput"
      ></textarea>
      <label for="textareaInput" class="sr-only"
        >Compose Your Message to Interact with the AI</label
      >
      <Button
        type="submit"
        class="p-3 disabled:opacity-50 h-full rounded-none"
        :disabled="chatStore.chatPending"
      >
        <img
          v-if="chatStore.chatPending"
          src="@/assets/icons/white-loading-icon.svg"
          alt="Loading AI Response"
          class="w-8"
        />
        <span v-else>Submit</span>
      </Button>
    </form>

    <!-- Google Search -->
    <Toggle
      aria-label="Toggle google search"
      @click="toggleGoogleSearch()"
      variant="outline"
      :class="toggleClasses"
    >
      <img :src="googleIcon" alt="" class="w-[26px] pointer-events-none" />
    </Toggle>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useChatStore } from '@/stores/chatStore';
import { Toggle } from '@/components/ui/toggle';
import { Button } from '@/components/ui/button';

const chatStore = useChatStore();
const userMessage = ref('');

const submitMessage = () => {
  const chat = {
    index: chatStore.chats.length,
    userMessage: userMessage.value,
    aiResponse: {},
    aiEngine: chatStore.activeAiModel.engine
  };

  chatStore.chats.push(chat);
  userMessage.value = '';

  chatStore.getAIResponse(chat);
};

const handleEnter = (event) => {
  if (chatStore.chatPending) {
    return;
  }

  if (!event.key === 'Enter' && event.shiftKey) {
    return;
  }
  event.preventDefault();

  submitMessage();
};

const toggleClasses = computed(() => {
  if (!chatStore.isGoogleSearchOn) {
    return 'border-neutral-600';
  }

  return 'data-[state=on]:bg-neutral-600 data-[state=on]:text-neutral-50';
});

const googleIcon = computed(() => {
  if (chatStore.isGoogleSearchOn) {
    return require('@/assets/icons/google-active-icon.svg');
  }

  return require('@/assets/icons/google-inactive-icon.svg');
});

function toggleGoogleSearch() {
  chatStore.isGoogleSearchOn = !chatStore.isGoogleSearchOn;
}
</script>
