<template>
  <div class="w-4/5 mx-auto">
    <h1 class="text-2xl font-bold text-center">Add Market Data</h1>

    <Dialog>
      <form
        @submit.prevent="onSubmit"
        id="market-data-form"
        class="flex flex-col gap-4 items-center max-w-lg mx-auto w-full mt-2"
      >
        <FormField
          v-slot="{ componentField }"
          name="title"
          key="title"
          class="w-full"
        >
          <FormItem class="w-full flex items-start flex-col">
            <FormLabel>Title</FormLabel>
            <FormMessage />
            <FormDescription>Title of the text content</FormDescription>
            <FormControl>
              <Input type="text" placeholder="Title" v-bind="componentField" />
            </FormControl>
          </FormItem>
        </FormField>

        <FormField
          v-slot="{ componentField }"
          name="tag"
          key="tag"
          class="w-full"
        >
          <FormItem class="w-full flex items-start flex-col">
            <FormLabel>Tag</FormLabel>
            <FormMessage />
            <FormDescription>Tag of the text content</FormDescription>

            <div class="relative w-full">
              <FormControl>
                <Input
                  type="text"
                  placeholder="Tag"
                  v-bind="componentField"
                  @focus="tagInputFocused = true"
                  @blur="setTagInputFocus(false)"
                />
              </FormControl>

              <ul
                v-if="filteredMarketTags.length > 0"
                :class="tagInputFocused ? 'visible' : 'invisible'"
                class="w-full bg-neutral-400 rounded drop-shadow-lg absolute -bottom-2 translate-y-full"
              >
                <li
                  v-for="marketTag in filteredMarketTags"
                  :key="marketTag.tag"
                >
                  <button
                    class="w-full py-2 text-neutral-50 hover:text-orange-400 border-b hover:border-orange-400"
                    @click="setTag(marketTag.tag)"
                    type="button"
                  >
                    {{ marketTag.tag }}
                  </button>
                </li>
              </ul>
            </div>
          </FormItem>
        </FormField>

        <DropdownMenu>
          <DropdownMenuTrigger as-child>
            <Button variant="outline">Choose Upload Type</Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent class="w-56">
            <DropdownMenuLabel>Upload Types</DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuRadioGroup v-model="activeDropdownOption">
              <DropdownMenuRadioItem
                v-for="dropdownOption in dropdownOptions"
                :key="dropdownOption"
                :value="dropdownOption"
              >
                {{ dropdownOption }}
              </DropdownMenuRadioItem>
            </DropdownMenuRadioGroup>
          </DropdownMenuContent>
        </DropdownMenu>

        <FormField
          v-slot="{ componentField }"
          name="content"
          key="content"
          class="w-full"
          v-if="activeDropdownOption === 'Text Content'"
        >
          <FormItem class="w-full flex flex-col">
            <FormLabel>Content</FormLabel>
            <FormMessage />
            <FormDescription
              >Text content that the user wants to be embedded to the
              model</FormDescription
            >
            <FormControl>
              <Textarea placeholder="Content..." v-bind="componentField" />
            </FormControl>
          </FormItem>
        </FormField>

        <div
          class="grid w-full max-w-sm items-center gap-1.5"
          v-if="activeDropdownOption === 'File Upload'"
        >
          <Label for="file">File Upload</Label>
          <p class="text-sm text-muted-foreground">Upload a CSV or PDF</p>
          <Input
            id="file"
            type="file"
            @change="onFileChanged($event)"
            accept=".xlsx,.csv,.pdf"
          />
        </div>

        <DialogTrigger :disabled="isSubmitButtonDisabled">
          <Button type="button" class="mt-4" :disabled="isSubmitButtonDisabled">
            <img
              v-if="isSubmitButtonDisabled"
              src="@/assets/icons/white-loading-icon.svg"
              alt="Content being uploaded"
              class="w-8"
            />
            <span v-else>Submit</span></Button
          >
        </DialogTrigger>

        <DialogContent>
          <DialogHeader>
            <DialogTitle>Add Market Data Confirmation</DialogTitle>
            <DialogDescription>
              Are you sure that you want to add Market data?
            </DialogDescription>
          </DialogHeader>

          <DialogFooter class="sm:justify-center">
            <DialogClose>
              <Button
                type="submit"
                form="market-data-form"
                :disabled="isSubmitButtonDisabled"
                >Submit</Button
              >
            </DialogClose>

            <DialogClose>
              <Button type="button" variant="secondary">Close</Button>
            </DialogClose>
          </DialogFooter>
        </DialogContent>
      </form>
    </Dialog>
  </div>
</template>

<script setup>
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose
} from '@/components/ui/dialog';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from '@/components/ui/dropdown-menu';
import { Textarea } from '@/components/ui/textarea';
import { Label } from '@/components/ui/label';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { useForm } from 'vee-validate';
import * as z from 'zod';
import { toTypedSchema } from '@vee-validate/zod';
import { useDataStore } from '@/stores/dataStore';
import { ref, onMounted, computed } from 'vue';

const dataStore = useDataStore();
const isSubmitButtonDisabled = ref(false);
const file = ref();
const marketTags = ref([]);
const tagInputFocused = ref(false);

const dropdownOptions = ['Text Content', 'File Upload'];
const activeDropdownOption = ref(dropdownOptions[0]);

const formSchema = toTypedSchema(
  z.object({
    title: z.string().min(1),
    tag: z.string().min(1),
    content: z.string().optional()
  })
);

const form = useForm({
  validationSchema: formSchema
});

onMounted(async () => {
  marketTags.value = await dataStore.getMarketTags();
});

const filteredMarketTags = computed(() => {
  return marketTags.value.filter((marketTag) =>
    marketTag.tag.toLowerCase().includes(form.values.tag?.toLowerCase())
  );
});

const onSubmit = form.handleSubmit(async (formValues, actions) => {
  if (!formValues.content && !file.value) {
    form.setFieldError('content', 'Add text content or file upload');
    form.setFieldError('file', 'Add text content or file upload');

    return;
  }

  if (formValues.content && file.value) {
    form.setFieldError(
      'content',
      'Remove either the text content or file upload'
    );
    form.setFieldError('file', 'Remove either the text content or file upload');

    return;
  }

  isSubmitButtonDisabled.value = true;

  try {
    if (file.value) {
      await dataStore.addFileMarketData(formValues, file.value);
    } else {
      await dataStore.addMarketData(formValues);
    }
  } catch (error) {
    // Do nothing here for now
  }

  actions.resetForm();
  file.value = null;
  const fileInputElement = document.getElementById('file');
  if (fileInputElement) {
    fileInputElement.value = null;
  }

  isSubmitButtonDisabled.value = false;
});

function onFileChanged($event) {
  const target = $event.target;
  if (target && target.files) {
    file.value = target.files[0];
  }
}

function setTagInputFocus(focus) {
  setTimeout(() => {
    tagInputFocused.value = focus;
  }, 200);
}

function setTag(tag) {
  form.setFieldValue('tag', tag);
}
</script>
