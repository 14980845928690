import { defineStore } from 'pinia';
import axios from 'axios';
import { AI_MODELS } from '@/lib/aiModels';
import { useToast } from '@/components/ui/toast/use-toast';
import { TOAST_GENERIC_ERROR } from '@/lib/constants';

const { toast } = useToast();

const storedChatsJsonString = localStorage.getItem('chats');
let storedChats = [];
if (storedChatsJsonString) {
  storedChats = JSON.parse(storedChatsJsonString);
}

export const useChatStore = defineStore('chatStore', {
  state: () => {
    return {
      isAppLoading: true,
      chats: storedChats,
      chatPending: false,
      gptModels: null,
      activeGptModel: null,
      gptModelKValue: 1,
      activeSearchCategory: null,
      activeSearchResult: null,
      activeAiModel: AI_MODELS[0],
      searchRequestPending: false,
      isGoogleSearchOn: false
    };
  },
  getters: {
    searchCategories: (state) => {
      const { searchCategories } = state.activeAiModel.chat;

      state.activeSearchCategory = searchCategories[0];

      return searchCategories;
    }
  },
  actions: {
    async getAIResponse(chat) {
      this.chatPending = true;
      const activeChat = this.chats[chat.index];

      try {
        const activeChatEndpoint = this.activeAiModel.chat.endpoints.find(
          (endpoint) =>
            endpoint.searchCategory === this.activeSearchCategory.apiName
        );

        if (!activeChatEndpoint) {
          toast(TOAST_GENERIC_ERROR);

          return;
        }

        const requestParams = {
          prompt: chat.userMessage
        };

        activeChatEndpoint.params.forEach((param) => {
          if (this[param.stateName] === null) {
            return;
          }

          requestParams[param.apiName] = this[param.stateName];
        });

        const response = await axios.get(activeChatEndpoint.endpoint, {
          params: requestParams,
          headers: {
            'x-api-key': process.env.VUE_APP_API_KEY
          }
        });
        const data = response.data.data;

        activeChat.aiModel = this.activeAiModel.name;
        activeChat.aiResponse.message = data.response;
        activeChat.aiResponse.metaData = data.embeddingMetaData;

        this.chatPending = false;

        this.updateChatsCache();
      } catch (error) {
        console.error(error);

        activeChat.aiResponse.message =
          'Apologies, we encountered an error processing the AI response on the server. Please retry or reach out to the owner for assistance.';
        activeChat.aiResponse.error = true;

        this.chatPending = false;
      }
    },
    async getGptModels() {
      try {
        const response = await axios.get(
          'https://suykeejg83.execute-api.us-east-1.amazonaws.com/prod/ehg/info/supportedGPTmodels',
          {
            headers: {
              'x-api-key': process.env.VUE_APP_API_KEY
            }
          }
        );

        const data = response.data.data;
        this.gptModels = data.gptModels;

        const defaultGptModel = this.gptModels.find(
          (gptModel) => gptModel.default
        );
        this.activeGptModel = defaultGptModel.name;

        this.isAppLoading = false;
      } catch (error) {
        console.error(error);
        toast(TOAST_GENERIC_ERROR);
      }
    },
    async getSearchResults(searchText) {
      this.searchRequestPending = true;

      try {
        const response = await axios.get(
          'https://suykeejg83.execute-api.us-east-1.amazonaws.com/prod/ehg/info/universalLookUp',
          {
            params: {
              category: this.activeSearchCategory.apiName,
              search: searchText
            },
            headers: {
              'x-api-key': process.env.VUE_APP_API_KEY
            }
          }
        );
        const data = response.data.data;

        this.searchRequestPending = false;

        return data;
      } catch (error) {
        console.error(error);
        toast(TOAST_GENERIC_ERROR);

        this.searchRequestPending = false;
      }
    },
    async submitUserFeedback(chat) {
      try {
        const chatContent = `USER_QUESTION: "${chat.userMessage}" \n GPT_RESPONSE: "${chat.aiResponse.message}"`;

        const requestParams = {
          tag: 'ehg',
          content: chatContent,
          engine: chat?.aiEngine ?? 'patent',
          feedback: true
        };

        await axios.post(
          'https://suykeejg83.execute-api.us-east-1.amazonaws.com/prod/ehg/info/ehgUserFeedback',
          requestParams,
          {
            headers: { 'x-api-key': process.env.VUE_APP_API_KEY }
          }
        );

        chat.hasSubmittedFeedback = true;

        this.updateChatsCache();
      } catch (error) {
        toast(TOAST_GENERIC_ERROR);
        throw new Error(error);
      }
    },
    updateChatsCache() {
      localStorage.setItem(
        'chats',
        JSON.stringify(
          this.chats.length > 10 ? this.chats.slice(-10) : this.chats
        )
      );
    }
  }
});
