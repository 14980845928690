<template>
  <div class="lg:w-4/5 mx-auto pb-4 px-12 pt-[80px] overflow-y-auto chat-messages h-full">
    <div
      v-for="(chat, index) in chatStore.chats"
      :key="index"
      class="text-left"
    >
      <p class="mt-2">
        <span class="font-bold">{{ user.name }}: </span>{{ chat.userMessage }}
      </p>

      <div v-if="chat.aiResponse.message" class="mt-2 relative">
        <Dialog>
          <DialogTrigger class="absolute -translate-x-full -left-4 w-[20px]">
            <img :src="getThumbIcon(chat)" alt="Submit positive feedback" />
          </DialogTrigger>

          <DialogContent v-if="chat.hasSubmittedFeedback">
            <DialogHeader>
              <DialogTitle>Feedback Has Been Submitted</DialogTitle>
            </DialogHeader>

            <DialogDescription>
              The feedback for the selected response has already been submitted.
            </DialogDescription>

            <DialogFooter class="sm:justify-center">
              <DialogClose>
                <Button>Close</Button>
              </DialogClose>
            </DialogFooter>
          </DialogContent>

          <DialogContent v-else>
            <DialogHeader>
              <DialogTitle>Submit User Feedback Confirmation</DialogTitle>
            </DialogHeader>

            <DialogDescription>
              Are you sure you want to submit that feedback as positive?
            </DialogDescription>

            <DialogFooter class="sm:justify-center">
              <DialogClose>
                <Button @click="onClick(chat)">Submit</Button>
              </DialogClose>

              <DialogClose>
                <Button type="button" variant="secondary">Close</Button>
              </DialogClose>
            </DialogFooter>
          </DialogContent>
        </Dialog>

        <p :class="[{ 'text-red-500': chat.aiResponse.error }]">
          <span class="font-bold">{{ chat.aiModel }} AI: </span
          ><span
            class="markdown"
            v-html="parseMarkdown(chat.aiResponse.message)"
          ></span>
        </p>
      </div>

      <div v-else class="flex items-center justify-center">
        <p>Loading AI Response...</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useChatStore } from '@/stores/chatStore';
import { watch, nextTick } from 'vue';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { marked } from 'marked';
import { useAuth0 } from '@auth0/auth0-vue';

const { user } = useAuth0();

const chatStore = useChatStore();

function scrollChat() {
  const chatMessages = document.querySelector('.chat-messages');
  chatMessages.scrollTo({
    top: chatMessages.scrollHeight,
    behavior: 'smooth'
  });
}

async function onClick(chat) {
  try {
    await chatStore.submitUserFeedback(chat);
  } catch (error) {
    console.error(error);
  }
}

function getThumbIcon(chat) {
  return chat.hasSubmittedFeedback
    ? require('@/assets/icons/thumbs-up-active.svg')
    : require('@/assets/icons/thumbs-up-inactive.svg');
}

// Marked Renderer extension to open links in new tab
const renderer = new marked.Renderer();
// eslint-disable-next-line no-unused-vars
renderer.link = function (href, title, text) {
  const link = marked.Renderer.prototype.link.apply(this, arguments);
  return link.replace('<a', "<a target='_blank'");
};

marked.setOptions({
  renderer: renderer
});

function parseMarkdown(message) {
  return marked.parse(message);
}

watch(chatStore.chats, () => {
  nextTick(() => {
    scrollChat();
  });
});

watch(
  () => chatStore.isAppLoading,
  () => {
    if (chatStore.isAppLoading) {
      return;
    }

    scrollChat();
  }
);
</script>

<style>
.markdown a {
  color: revert;
}
</style>
