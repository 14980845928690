<template>
  <div class="w-5/6 mx-auto pb-[100px]">
    <div class="flex">
      <div class="max-w-52 mr-auto mb-4 text-sm font-medium">
        <Label for="filter" class="text-left block">Filter by tags</Label>
        <Input
          id="filter"
          type="text"
          placeholder="Tag name..."
          v-model="filterPhrase"
        />
      </div>

      <div>
        <Button type="submit" class="mt-2" @click="refreshTasks()"
          >Refresh</Button
        >
      </div>
    </div>

    <Table>
      <TableHeader>
        <TableRow class="items-center">
          <TableHead
            class="text-center font-bold"
            v-for="tableHeader in chatStore.activeAiModel.tasks.tableHeaders"
            :key="tableHeader"
            >{{ tableHeader }}</TableHead
          >

          <TableHead class="text-center font-bold">Delete</TableHead>
        </TableRow>
      </TableHeader>
      <div
        v-if="isTasksLoading"
        class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
      >
        <img
          src="@/assets/icons/loading-icon.svg"
          alt="Loading AI Response"
          class="w-20"
        />
      </div>
      <TableBody v-else>
        <TableRow v-for="task in filteredTasks" :key="task.id">
          <TableCell class="font-medium">{{ task.id }}</TableCell>
          <TableCell>{{ task.status }}</TableCell>
          <TableCell v-if="task.source">{{ task.source }}</TableCell>
          <TableCell>{{ task.query }}</TableCell>
          <TableCell>{{ task.tag }}</TableCell>
          <TableCell>{{ formatDate(task.created_at) }}</TableCell>
          <TableCell class="text-center">
            <button @click="removeTask(task.id)">
              <img
                src="@/assets/icons/trash-icon.svg"
                alt="Delete Task"
                class="max-w-[24px]"
              />
            </button>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </div>
</template>

<script setup>
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from '@/components/ui/table';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { useTasksStore } from '@/stores/tasksStore';
import { useChatStore } from '@/stores/chatStore';
import { onMounted, ref, watch, computed } from 'vue';
import { DateTime } from 'luxon';
import { useToast } from '@/components/ui/toast/use-toast';
import { Button } from '@/components/ui/button';

const { toast } = useToast();

const tasksStore = useTasksStore();
const chatStore = useChatStore();

const tasks = ref([]);
const filterPhrase = ref('');
const isTasksLoading = ref(true);

onMounted(async () => {
  isTasksLoading.value = true;

  await refreshTasks();

  isTasksLoading.value = false;
});

const filteredTasks = computed(() => {
  return tasks.value.filter((task) => {
    if (filterPhrase.value === '') {
      return true;
    }

    return task.tag.toLowerCase().includes(filterPhrase.value.toLowerCase());
  });
});

async function refreshTasks() {
  tasks.value = await tasksStore.getTasks();
}

async function removeTask(taskId) {
  try {
    tasks.value = tasks.value.filter((task) => task.id !== taskId);
    await tasksStore.updateTask({ id: taskId });
  } catch (error) {
    console.error(error);
    toast({
      title: 'Uh oh! Something went wrong.',
      description: 'If the problem persists please contact an admin.',
      variant: 'destructive'
    });
  }
}

function formatDate(dateString) {
  const date = new DateTime(dateString);
  return date.toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS);
}

watch(
  () => chatStore.activeAiModel,
  async () => {
    isTasksLoading.value = true;

    tasks.value = await tasksStore.getTasks();

    isTasksLoading.value = false;
  }
);
</script>
