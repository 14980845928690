<template>
  <form
    @submit.prevent="onSubmit"
    class="flex flex-col gap-2 items-center max-w-lg mx-auto lg:w-full w-5/6"
  >
    <FormField
      v-slot="{ componentField }"
      :name="param.stateName"
      v-for="param in chatStore.activeAiModel.tasks.update.params"
      :key="param.apiName"
      class="w-full"
    >
      <FormItem class="w-full">
        <FormLabel>{{ param.readableName }}</FormLabel>
        <FormDescription>{{ param.description }}</FormDescription>
        <FormControl>
          <Input
            type="text"
            :placeholder="param.readableName"
            v-bind="componentField"
            v-if="param.inputType === 'text'"
          />

          <multiselect
            v-if="param.inputType === 'select'"
            v-model="selectedVendors"
            placeholder="Vendors"
            :options="vendorsSelectOptions"
            mode="tags"
            :classes="{
              tag: 'bg-neutral-500 text-white text-sm font-semibold py-0.5 pl-2 rounded mr-1 mb-1 flex items-center whitespace-nowrap min-w-0 rtl:pl-0 rtl:pr-2 rtl:mr-0 rtl:ml-1',
              container:
                'relative mx-auto w-full flex items-center justify-end box-border cursor-pointer border border-gray-300 rounded bg-white text-base leading-snug outline-none h-[60px]',
              containerActive: 'ring ring-neutral-950',
              tags: 'flex-grow flex-shrink flex flex-wrap items-center pt-1 pl-2 min-w-0 rtl:pl-0 rtl:pr-2 max-h-[60px] overflow-y-auto',
              clear: 'px-3.5 relative z-10 opacity-40 transition duration-300 flex-shrink-0 flex-grow-0 flex hover:opacity-80 rtl:pr-0 rtl:pl-3.5',
            }"
          ></multiselect>
        </FormControl>
        <FormMessage />
      </FormItem>
    </FormField>

    <Button type="submit" class="mt-2" :disabled="isSubmitButtonDisabled"
      >Submit</Button
    >
  </form>
</template>

<script setup>
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { useForm } from 'vee-validate';
import Multiselect from '@vueform/multiselect';
import * as z from 'zod';
import { toTypedSchema } from '@vee-validate/zod';
import { useTasksStore } from '@/stores/tasksStore';
import { useChatStore } from '@/stores/chatStore';
import { onMounted, ref, computed, watch } from 'vue';

const tasksStore = useTasksStore();
const chatStore = useChatStore();

const vendors = ref([]);
const selectedVendors = ref([]);
const isSubmitButtonDisabled = ref(false);

const formSchema = toTypedSchema(
  z.object({
    taskId: z.string().optional(),
    query: z.string().optional(),
    tag: z.string().optional()
  })
);

const form = useForm({
  validationSchema: formSchema
});

const onSubmit = form.handleSubmit(async (formValues, actions) => {
  isSubmitButtonDisabled.value = true;

  if (selectedVendors.value.length > 0) {
    formValues.vendors = selectedVendors.value;
  }

  await tasksStore.updateTask(formValues);

  actions.resetForm();
  isSubmitButtonDisabled.value = false;
});

onMounted(async () => {
  vendors.value = await tasksStore.getVendors();
});

const vendorsSelectOptions = computed(() => {
  return vendors.value.map((vendor) => {
    return {
      label: vendor.name,
      value: vendor.source
    };
  });
});

watch(
  () => chatStore.activeAiModel,
  () => {
    form.resetForm();
    selectedVendors.value = [];
  }
);
</script>

<style>
@import '@vueform/multiselect/themes/default.css';
</style>
