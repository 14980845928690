<template>
  <div class="flex w-full justify-center">
    <AIDropdown :hasFullyRoundedBorder="false">
      <template #buttonTitle
        ><span id="searchCategory" class="whitespace-nowrap"
          >Search: {{ activeSearchCategory?.readableName }}</span
        ></template
      >
      <template #dropdownContent>
        <div
          v-for="searchCategory in searchCategories"
          :key="searchCategory.apiName"
          class="flex items-center justify-between mt-2 p-2"
        >
          <label :for="searchCategory.apiName" class="cursor-pointer">{{
            searchCategory.readableName
          }}</label>
          <input
            name="searchCategories"
            type="radio"
            :id="searchCategory.apiName"
            :value="searchCategory"
            v-model="activeSearchCategory"
          />
        </div>
      </template>
    </AIDropdown>

    <div class="search relative w-full" ref="searchElement">
      <div
        class="search__results drop-shadow-lg rounded absolute -translate-y-full -top-2 bg-neutral-400 w-full flex flex-col gap-3"
      >
        <ul v-if="showSearchResults" class="p-2">
          <li v-for="result in searchResults" :key="result">
            <button
              class="w-full h-full text-neutral-50"
              @click="
                setActiveSearchResult(result[activeSearchCategory.resultName])
              "
            >
              {{ result[activeSearchCategory.resultName] }}
            </button>
          </li>
        </ul>

        <div v-else-if="isSearchResultsOpen" class="p-2 text-neutral-50">
          <p v-if="searchRequestPending">Searching...</p>
          <p v-else>No results found</p>
        </div>
      </div>

      <form
        class="relative bg-neutral-400 flex items-center justify-between h-full"
        @submit.prevent="handleSearch()"
      >
        <input
          type="search"
          class="h-full bg-neutral-400 lg:px-2 w-full"
          aria-labelledby="searchCategory"
          v-model="searchText"
          @focus="handleFocus()"
        />
        <button type="submit" class="px-2 w-9 h-full">
          <img
            src="@/assets/icons/search-bar-icon.svg"
            alt="Search"
            class="w-5"
          />
        </button>
      </form>
    </div>

    <div
      class="bg-neutral-600 text-neutral-50 flex items-center rounded-r-xl p-2"
    >
      <p class="whitespace-nowrap">
        <span class="hidden lg:inline"
          >Active {{ activeSearchCategory?.readableName }}:</span
        >
        {{ activeSearchResult ? activeSearchResult : 'None' }}
      </p>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted, watch } from 'vue';
import AIDropdown from '@/components/chat/AIDropdown';
import { useChatStore } from '@/stores/chatStore';
import { storeToRefs } from 'pinia';

onMounted(() => {
  document.addEventListener('click', closeSearchResultsOutside);
});

onUnmounted(() => {
  document.removeEventListener('click', closeSearchResultsOutside);
});

const chatStore = useChatStore();
const {
  searchCategories,
  activeSearchCategory,
  activeSearchResult,
  searchRequestPending
} = storeToRefs(useChatStore());

const searchText = ref('');
const searchResults = ref([]);
const isSearchResultsOpen = ref(false);
const searchElement = ref(null);

const showSearchResults = computed(() => {
  return isSearchResultsOpen.value && searchResults.value.length > 0;
});

async function handleSearch() {
  searchResults.value = await chatStore.getSearchResults(searchText.value);
  isSearchResultsOpen.value = true;
}

function handleFocus() {
  isSearchResultsOpen.value = true;
}

function setActiveSearchResult(activeSearchResult) {
  chatStore.activeSearchResult = activeSearchResult;

  isSearchResultsOpen.value = false;
}

function closeSearchResultsOutside(event) {
  if (searchElement.value && !searchElement.value.contains(event.target)) {
    isSearchResultsOpen.value = false;
  }
}

watch(activeSearchCategory, () => {
  searchText.value = '';
  searchResults.value = [];
  isSearchResultsOpen.value = false;
  chatStore.activeSearchResult = null;
});
</script>
