import { defineStore } from 'pinia';
import axios from 'axios';
import { useToast } from '@/components/ui/toast/use-toast';
import { TOAST_GENERIC_ERROR } from '@/lib/constants';

const CHUNK_SIZE = 1.8 * 1024 * 1024; // 1.8 MB in bytes

const { toast } = useToast();

export const useDataStore = defineStore('dataStore', {
  actions: {
    async addMarketData(marketData) {
      try {
        const response = await axios.post(
          'https://suykeejg83.execute-api.us-east-1.amazonaws.com/prod/ehg/marketData/ehgAddMarketData',
          marketData,
          {
            headers: { 'x-api-key': process.env.VUE_APP_API_KEY }
          }
        );

        return response;
      } catch (error) {
        console.error(error);
        toast(TOAST_GENERIC_ERROR);
      }
    },
    async addFileMarketData(marketData, file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = async function () {
          const base64String = reader.result.split(',')[1]; // Get the base64 string without the Data URL prefix

          let start = 0;
          let end = CHUNK_SIZE;
          const fileChunks = [];

          while (start < base64String.length) {
            fileChunks.push(base64String.slice(start, end));
            start = end;
            end = start + CHUNK_SIZE;
          }

          for (let i = 0; i < fileChunks.length; i++) {
            const requestParams = {
              file_content: fileChunks[i],
              file_type: 'pdf',
              tag: marketData.tag,
              title: marketData.title,
              user: 'ehg'
            };

            try {
              await axios.post(
                'https://suykeejg83.execute-api.us-east-1.amazonaws.com/prod/ehg/network/uploadFile',
                requestParams,
                {
                  headers: { 'x-api-key': process.env.VUE_APP_API_KEY }
                }
              );
            } catch (error) {
              toast(TOAST_GENERIC_ERROR);
              reject();

              return;
            }
          }

          resolve();
        };
      });
    },
    async getMarketTags() {
      try {
        const response = await axios.get(
          'https://suykeejg83.execute-api.us-east-1.amazonaws.com/prod/ehg/info/getAllMarketDataTags',
          {
            headers: { 'x-api-key': process.env.VUE_APP_API_KEY }
          }
        );

        return response.data.data;
      } catch (error) {
        console.error(error);
        toast(TOAST_GENERIC_ERROR);
      }
    }
  }
});
