<template>
  <div
    class="ai-models top-4 absolute z-40 left-2/4 -translate-x-1/2 text-xl font-bold"
  >
    <AIDropdown :expandDown="true">
      <template #buttonTitle>
        <div class="px-3 py-1">
          <span class="uppercase whitespace-nowrap" >Active AI: {{ activeAiModel.name }}</span>
          <p class="text-xs text-left">powered by <span class="text-orange-400">R.I.K.I.</span></p>
        </div>
      </template>
      <template #dropdownContent>
        <div
          v-for="aiModel in AI_MODELS"
          :key="aiModel"
          class="flex items-center justify-between mt-2 p-2"
        >
          <label :for="aiModel.name" class="cursor-pointer">{{
            aiModel.name
          }}</label>
          <input
            name="gptModels"
            type="radio"
            :id="aiModel.name"
            :value="aiModel"
            v-model="activeAiModel"
          />
        </div>
      </template>
    </AIDropdown>
  </div>
</template>

<script setup>
import AIDropdown from '@/components/chat/AIDropdown';
import { useChatStore } from '@/stores/chatStore';
import { storeToRefs } from 'pinia';
import { AI_MODELS } from '@/lib/aiModels';

const { activeAiModel } = storeToRefs(useChatStore());
</script>
