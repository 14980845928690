import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '@/views/HomeView.vue';
import ChatView from '@/views/ChatView.vue';
import TasksView from '@/views/TasksView.vue';
import DataEntryView from '@/views/DataEntryView.vue';
import { authGuard, useAuth0 } from '@auth0/auth0-vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/chat',
    name: 'Chat',
    component: ChatView,
    beforeEnter: authGuard
  },
  {
    path: '/tasks',
    name: 'Tasks',
    component: TasksView,
    beforeEnter: authGuard
  },
  {
    path: '/data',
    name: 'DataEntry',
    component: DataEntryView,
    beforeEnter: authGuard
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: 'text-orange-400 font-bold'
});

router.beforeResolve((to, from, next) => {
  const { isAuthenticated } = useAuth0();

  if (
    isAuthenticated &&
    to.name !== 'Chat' &&
    to.name !== 'Tasks' &&
    to.name !== 'DataEntry'
  ) {
    next({
      name: 'Chat'
    });
  }

  next();
});

export default router;
