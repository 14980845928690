<template>
  <nav class="bg-neutral-500 text-neutral-50 py-3">
    <div
      class="flex items-center justify-between lg:px-10 px-6 max-w-screen-2xl mx-auto"
    >
      <p v-if="user.name" class="text-sm lg:text-base">
        Welcome, <span class="font-bold">{{ user.name }}</span
        >!
      </p>

      <div class="flex gap-8" v-if="!isAuthenticated">
        <LoginButton />
      </div>

      <div class="relative">
        <button @click="toggleMenu()" class="block lg:hidden">
          <div class="three col">
            <div
              :class="['hamburger', { 'is-active': isMenuOpen }]"
              id="hamburger"
            >
              <span class="line bg-neutral-50"></span>
              <span class="line bg-neutral-50"></span>
              <span class="line bg-neutral-50"></span>
            </div>
          </div>
        </button>

        <div
          class="flex gap-4 absolute flex-col top-8 left-2/4 -translate-x-3/4 z-50 bg-neutral-700 w-[140px] p-2 rounded-md shadow-md items-center lg:static lg:bg-neutral-500 lg:shadow-none lg:flex-row lg:translate-x-0 lg:w-full"
          v-if="isAuthenticated && isMenuOpen"
          @click="toggleMenu()"
        >
          <RouterLink to="/chat">Chat</RouterLink>
          <RouterLink to="/tasks" v-if="hasAdminAccess">Tasks</RouterLink>
          <RouterLink to="/data" v-if="hasAdminAccess">Data Entry</RouterLink>
          <LogoutButton class="lg:ml-4" />
        </div>
      </div>
    </div>
  </nav>
</template>

<script setup>
import { computed, ref, onMounted, watch, nextTick } from 'vue';
import { RouterLink } from 'vue-router';
import LoginButton from '@/components/auth/LoginButton';
import LogoutButton from '@/components/auth/LogoutButton';
import { useAuth0 } from '@auth0/auth0-vue';

const MOBILE_WIDTH = 1024;
const isMobile = ref(false);
const isMenuOpen = ref(false);

const { isAuthenticated, user } = useAuth0();

const ROLES = {
  ADMIN: 'admin'
};

const hasAdminAccess = computed(() => {
  if (!user.value.app_metadata) {
    return false;
  }

  return user.value.app_metadata?.roles?.includes(ROLES.ADMIN);
});

onMounted(async () => {
  window.addEventListener('resize', onResize);
  onResize();

  await nextTick();
  isMenuOpen.value = isMobile.value ? false : true;
});

async function onResize() {
  await nextTick();
  isMobile.value = window.innerWidth <= MOBILE_WIDTH;
}

function toggleMenu() {
  if (!isMobile.value) {
    return;
  }

  isMenuOpen.value = !isMenuOpen.value;
}

watch(isMobile, (isMobile) => {
  isMenuOpen.value = isMobile ? false : true;
});
</script>

<style scoped>
img {
  width: 302px;
}

.col {
  display: block;
  float: left;
  margin: 1% 0 1% 1.6%;
}

.hamburger .line {
  width: 20px;
  height: 3px;
  display: block;
  margin: 3px auto;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

#hamburger.is-active .line:nth-child(2) {
  opacity: 0;
}

#hamburger.is-active .line:nth-child(1) {
  -webkit-transform: translateY(6px) rotate(45deg);
  -ms-transform: translateY(6px) rotate(45deg);
  -o-transform: translateY(6px) rotate(45deg);
  transform: translateY(6px) rotate(45deg);
}

#hamburger.is-active .line:nth-child(3) {
  -webkit-transform: translateY(-6px) rotate(-45deg);
  -ms-transform: translateY(-6px) rotate(-45deg);
  -o-transform: translateY(-6px) rotate(-45deg);
  transform: translateY(-6px) rotate(-45deg);
}
</style>
