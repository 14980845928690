import { createApp } from 'vue';
import App from './App.vue';
// @ts-ignore
import router from './router';
import { createPinia } from 'pinia';
import { createAuth0 } from '@auth0/auth0-vue';
import './index.css';

const pinia = createPinia();

createApp(App)
  .use(
    createAuth0({
      domain: process.env.VUE_APP_AUTH0_DOMAIN,
      clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
      authorizationParams: {
        redirect_uri: process.env.VUE_APP_CALLBACK_URL,
      },
    })
  )
  .use(router)
  .use(pinia)
  .mount('#app');
